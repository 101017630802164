import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { Footer } from "../../components/Footer/Footer";
import { Loader } from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import api from "../../AxiosInstance";

export const ResultPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState(0);
  const [afterGradData1, setAfterGradData1] = useState(null);
  const [afterGradData2, setAfterGradData2] = useState(null);
  const [isMultiple, setIsMultiple] = useState(false);
  const location = useLocation();

  const { colleges, departments } = location.state || {};

  const handleRef = (value) => {
    window.scrollTo({
      top: value * 400,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  const selectResultIndex = (i) => {
    setSelectedList(i);
    handleRef(i);
  };

  const resultIndex = [
    "Company & position specific breakdown",
    "Company breakdown",
    "Characteristic Carreer paths",
  ];

  const fetchAfterGradData = () => {
    console.log("colleges", colleges);
    console.log("departments", departments);
    setIsLoading(true);
    console.log(colleges[0], departments[0]);
    api
      .get("/fetchAfterGradData", {
        params: { colleges: colleges[0], departments: departments[0] },
      })
      .then((response) => {
        console.log(response.data.data);
        setAfterGradData1(response.data.data);
        // if (colleges.length > 1) {
        //   setIsMultiple(true);
        //   setAfterGradData2(response.data.data[1]);
        // } else {
        //   setIsMultiple(false);
        // }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAfterGradData();
  }, []);
  return (
    <>
      <div className="result-page">
        {isLoading && <Loader />}
        <div className="result-head">
          <div className="inner-result-head">
            {!isMultiple && (
              <>
                <div className="head-section-1">
                  {departments !== undefined ? (
                    <div className="text-1">
                      {colleges + " / " + departments}
                    </div>
                  ) : (
                    <div className="text-1">{colleges}</div>
                  )}
                  {/* <div className="text-1">{colleges + " / " + departments}</div> */}
                  <div className="text-2">
                    Based on career analysis of{" "}
                    {afterGradData1?.total_professional_count} professionals
                  </div>
                </div>
                {afterGradData1?.company_position_breakdown?.length > 0 && (
                  <div className="head-section-2">
                    <div className="text-3">
                      {"%" +
                        afterGradData1?.company_position_breakdown[0]
                          .professional_percent +
                        " " +
                        afterGradData1?.company_position_breakdown[0].org_role}
                    </div>
                    <div className="text-3">
                      @{afterGradData1?.company_position_breakdown[0].org_name}
                    </div>
                  </div>
                )}
                <div className="head-section-2">
                  <div className="text-3">%15 Master of Science</div>
                  <div className="text-3">
                    @Standard University / Computer Science
                  </div>
                </div>
                <div className="head-section-2">
                  <div className="text-3">After 10 years</div>
                  <div className="text-3">%40 CTO @Apple Inc.</div>
                </div>
              </>
            )}
            {isMultiple && (
              <>
                <div className="head-section-1">
                  <div className="text-1">
                    {colleges[0] + " / " + departments[0]}
                  </div>
                  {console.log(afterGradData1)}
                  <div className="text-2">
                    Based on carreer analysis of {afterGradData1?.total_professional_count} professionals
                  </div>
                </div>
                <div className="vs-text">VS</div>
                <div className="head-section-1">
                  <div className="text-1">
                    {colleges[1] + " / " + departments[1]}
                  </div>
                  <div className="text-2">
                    Based on carreer analysis of {afterGradData2?.total_professional_count} professionals
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!isMultiple && (
          <div className="result-body">
            <div
              className={"result-body-left " + `${showMenu ? "show" : "hide"}`}
            >
              <div className="result-index">
                {resultIndex.map((value, i) => {
                  return (
                    <div
                      className={
                        "result-index-list " +
                        `${selectedList == i ? "selected-list" : ""}`
                      }
                      onClick={() => selectResultIndex(i)}
                      key={i}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="result-body-right">
              <div className="result-data-1">
                <div className="result-data-head">
                  <div className="text-1">
                    Company & position specific breakdown
                  </div>
                  <div className="text-2">
                    In which position&company they work?
                  </div>
                </div>
                {afterGradData1?.company_position_breakdown?.length > 0 && (
                  <div className="result-data-body">
                    {afterGradData1?.company_position_breakdown.map(
                      (value, key) => {
                        return (
                          <ProgressBar
                            key={key}
                            text={
                              "%" +
                              value?.professional_percent +
                              " - " +
                              value.professional_count +
                              " graduates " +
                              " | " +
                              value.org_role +
                              " @" +
                              value.org_name
                            }
                            percentage={value.professional_percent + "%"}
                          />
                        );
                      }
                    )}
                  </div>
                )}
              </div>
              <div className="result-data-1">
                <div className="result-data-head">
                  <div className="text-1">Company breakdown</div>
                  <div className="text-2">In which company they work?</div>
                </div>
                <div className="result-data-body">
                  {afterGradData1?.company_breakdown?.length > 0 &&
                    afterGradData1?.company_breakdown.map((value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            " @" +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="result-data-1">
                <div className="result-data-head">
                  <div className="text-1">Characteristic Carreer paths</div>
                  <div className="text-2">
                    How is their after graduation carreer paths in terms of
                    position & company?
                  </div>
                </div>
                {afterGradData1?.after_one_year_data?.length > 0 && (
                  <div className="main-data-body">
                    <div className="data-body-label">
                      Up to 1 year after grad
                    </div>
                    <div className="result-data-body">
                      {afterGradData1?.after_one_year_data.map((value, key) => {
                        return (
                          <ProgressBar
                            key={key}
                            text={
                              "%" +
                              value.professional_percent +
                              " - " +
                              value.professional_count +
                              " graduates " +
                              " | " +
                              " @" +
                              value.org_name
                            }
                            percentage={value.professional_percent + "%"}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {afterGradData1?.after_one_to_three_year_data?.length > 0 && (
                  <div className="main-data-body">
                    <div className="data-body-label">
                      1 to 3 years after grad
                    </div>
                    <div className="result-data-body">
                      {afterGradData1?.after_one_to_three_year_data.map(
                        (value, key) => {
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " graduates " +
                                " | " +
                                " @" +
                                value.org_name
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {afterGradData1?.after_three_to_five_year_data?.length > 0 && (
                  <div className="main-data-body">
                    <div className="data-body-label">
                      3 to 5 years after grad
                    </div>
                    <div className="result-data-body">
                      {afterGradData1?.after_three_to_five_year_data.map(
                        (value, key) => {
                          return (
                            <ProgressBar
                              key={key}
                              text={
                                "%" +
                                value.professional_percent +
                                " - " +
                                value.professional_count +
                                " graduates " +
                                " | " +
                                " @" +
                                value.org_name
                              }
                              percentage={value.professional_percent + "%"}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        )}
        {isMultiple && (
          <div className="multiple-result-body">
            <div className="multiple-result-head">
              <div className="text-1">
                Company & position specific breakdown
              </div>
              <div className="text-2">In which position&company they work?</div>
            </div>
            <div className="multiple-result-data">
              {afterGradData1?.company_position_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData1?.company_position_breakdown.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_role +
                            " @" +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              )}
              {afterGradData2?.company_position_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData2?.company_position_breakdown.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_role +
                            " @" +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
            <div className="multiple-result-head">
              <div className="text-1">Company breakdown</div>
              <div className="text-2">In which company they work?</div>
            </div>
            <div className="multiple-result-data">
              {afterGradData1?.company_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData1?.company_breakdown.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              )}
              {afterGradData2?.company_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData2?.company_breakdown.map(
                    (value, key) => {
                      return (
                        <ProgressBar
                          key={key}
                          text={
                            "%" +
                            value?.professional_percent +
                            " - " +
                            value.professional_count +
                            " graduates " +
                            " | " +
                            value.org_name
                          }
                          percentage={value.professional_percent + "%"}
                        />
                      );
                    }
                  )}
                </div>
              )}
            </div>
            <div className="multiple-result-head">
              <div className="text-1">Characteristic Carreer paths</div>
              <div className="text-2">
                How is their after graduation carreer paths in terms of position
                & company?
              </div>
            </div>
            <div className="multiple-result-data">
              {afterGradData1?.company_position_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData1?.after_one_year_data?.length > 0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        Up to 1 year after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData1?.after_one_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {afterGradData1?.after_one_to_three_year_data?.length > 0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        1 to 3 years after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData1?.after_one_to_three_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {afterGradData1?.after_three_to_five_year_data?.length >
                    0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        3 to 5 years after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData1?.after_three_to_five_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {afterGradData2?.company_position_breakdown?.length > 0 && (
                <div className="multiple-data-body">
                  {afterGradData2?.after_one_year_data?.length > 0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        Up to 1 year after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData2?.after_one_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {afterGradData2?.after_one_to_three_year_data?.length > 0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        1 to 3 years after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData2?.after_one_to_three_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {afterGradData2?.after_three_to_five_year_data?.length >
                    0 && (
                    <div className="main-data-body">
                      <div className="data-body-label">
                        3 to 5 years after grad
                      </div>
                      <div className="result-data-body">
                        {afterGradData2?.after_three_to_five_year_data.map(
                          (value, key) => {
                            return (
                              <ProgressBar
                                key={key}
                                text={
                                  "%" +
                                  value.professional_percent +
                                  " - " +
                                  value.professional_count +
                                  " graduates " +
                                  " | " +
                                  " @" +
                                  value.org_name
                                }
                                percentage={value.professional_percent + "%"}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
