import React, { useEffect, useState } from "react";
import { Selector } from "../../components/Selector/Selector";
import { Button } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import GradIcon from "../../Icon.svg";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { ImCross } from "react-icons/im";
import "./style.css";

export const Homepage = () => {
  const [departmentCount, setDepartmentCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [collegeList, setCollegeList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [college, setCollege] = useState("");
  const [department, setDepartment] = useState("");
  const [college1, setCollege1] = useState("");
  const [department1, setDepartment1] = useState("");
  const [showCollegeList, setShowCollegeList] = useState(false);
  const [showDepartmentList, setShowDepartmentList] = useState(false);
  const [showCollegeList1, setShowCollegeList1] = useState(false);
  const [showDepartmentList1, setShowDepartmentList1] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedEducationId, setSelectedEducationId] = useState(null);
  const [selectedEducationId1, setSelectedEducationId1] = useState(null);

  const navigate = useNavigate();

  const addDepartment = () => {
    if (departmentCount < 2) {
      setDepartmentCount(departmentCount + 1);
    }
  };

  const closeDepartmentField = () => {
    if (departmentCount > 1) {
      setDepartmentCount(departmentCount - 1);
    }
  };

  const fetchEducations = (college) => {
    api
      .get("/fetchEducations", { params: { education: college } })
      .then((response) => {
        setCollegeList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEducationDepartments = (educationId, department) => {
    console.log('educationId', educationId);
    api
      .get("/fetchEducationDepartments", {
        params: { educationId: educationId, department: department },
      })
      .then((response) => {
        setDepartmentList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCollegeChange = (e) => {
    setCollege(e.target.value);
    fetchEducations(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
    fetchEducationDepartments(selectedEducationId, e.target.value);
  };

  const handleCollege1Change = (e) => {
    setCollege1(e.target.value);
    fetchEducations(e.target.value);
  };

  const handleDepartment1Change = (e) => {
    setDepartment1(e.target.value);
    fetchEducationDepartments(selectedEducationId1, e.target.value);
  };

  const handleCollegeClick = () => {
    setShowCollegeList(!showCollegeList);
    fetchEducations(college);
  };

  const handleDepartmentClick = () => {
    setShowDepartmentList(!showDepartmentList);
    fetchEducationDepartments(selectedEducationId, "");
  };

  const handleCollege1Click = () => {
    setShowCollegeList1(!showCollegeList1);
    fetchEducations(college1);
  };

  const handleDepartment1Click = () => {
    setShowDepartmentList1(!showDepartmentList1);
    fetchEducationDepartments(selectedEducationId1, "");
  };

  const selectCollege = (college) => {
    setCollege(college.name);
    setShowCollegeList(false);
    console.log(college.id);
    setSelectedEducationId(college.id);
    fetchEducationDepartments(college.id, "");
  };

  const selectDepartment = (department) => {
    setDepartment(department);
    setShowDepartmentList(false);
  };

  const selectCollege1 = (college) => {
    setCollege1(college.name);
    setShowCollegeList1(false);
    setSelectedEducationId1(college.id);
    fetchEducationDepartments(college.id, "");
  };

  const selectDepartment1 = (department) => {
    setDepartment1(department);
    setShowDepartmentList1(false);
  };

  const changeTab = (value) => {
    setSelectedTab(value);
    setShowCollegeList(false);
    setShowDepartmentList(false);
    setDepartmentCount(1);
    setDepartment("");
    setCollege("");
  };

  function setSubmitBtnDisable() {
    if (departmentCount == 1) {
      return college == "" || department == "";
    } else {
      return college1 == "" || department1 == "";
    }
  }

  const handleSubmit = () => {
    let data;
    if (departmentCount == 1) {
      data = {
        colleges: [college],
        departments: [department],
      };
    } else {
      data = {
        colleges: [college, college1],
        departments: [department, department1],
      };
    }
    navigate("/result", { state: data });
  };

  useEffect(() => {
    fetchEducations();
  }, []);

  return (
    <div className="homepage">
      {isLoading && <Loader />}
      <div className="inner-homepage">
        <div className="headline">
          Decide your undergrad based on after grad statistics
        </div>
        <div className="description">
          Type the university departments you are considering <br /> and well
          show you what will happen after graduation.
        </div>
        <Selector selectedTab={selectedTab} changeTab={changeTab} />
        <div className="analysis">
          <div className="college-department">
            <div className="analysis-fields">
              <div className="input-box">
                <div className="input-label">UNIVERSITY / COLLEGE</div>
              </div>
              {selectedTab == 1 && (
                <div className="input-box">
                  <div className="input-label">DEPARTMENT / SCHOOL</div>
                </div>
              )}
            </div>
            <div className="analysis-fields">
              <div
                className="input-box"
                style={{ width: selectedTab == 2 ? "100%" : "48%" }}
              >
                <div className="input-text">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Select university/college"
                    onChange={(e) => handleCollegeChange(e)}
                    value={college}
                    onClick={() => handleCollegeClick()}
                  />
                  {showCollegeList && (
                    <div className="option-list">
                      {collegeList.length > 0 &&
                        collegeList.map((college, index) => {
                          return (
                            <div
                              className="option"
                              key={index}
                              onClick={() => selectCollege(college)}
                            >
                              {college.name}
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {selectedTab == 2 && departmentCount > 1 && (
                    <div
                      className="cross-btn"
                      onClick={() => closeDepartmentField()}
                    >
                      <ImCross />
                    </div>
                  )}
                </div>
              </div>
              {selectedTab == 1 && (
                <div className="input-box">
                  <div className="input-text">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Select department/school"
                      onChange={(e) => handleDepartmentChange(e)}
                      value={department}
                      disabled={college == ""}
                      onClick={() => handleDepartmentClick()}
                    />
                    {showDepartmentList && (
                      <div className="option-list">
                        {departmentList.length > 0 &&
                          departmentList.map((department, index) => {
                            return (
                              <div
                                className="option"
                                key={index}
                                onClick={() =>
                                  selectDepartment(department.name)
                                }
                              >
                                {department.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {departmentCount > 1 && (
                      <div
                        className="cross-btn"
                        onClick={() => closeDepartmentField()}
                      >
                        <ImCross />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {departmentCount < 2 ? (
              <div className="add-dept">
                <button
                  className="add-dept-btn"
                  onClick={() => addDepartment()}
                >
                  {selectedTab == 1
                    ? "Add an other university / department"
                    : "Add an other university"}
                </button>
              </div>
            ) : (
              <div className="analysis-fields">
                <div
                  className="input-box"
                  style={{ width: selectedTab == 2 ? "100%" : "48%" }}
                >
                  <div className="input-text">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Select university/college"
                      onChange={(e) => handleCollege1Change(e)}
                      value={college1}
                      onClick={() => handleCollege1Click()}
                      disabled={college == "" || department == ""}
                    />
                    {showCollegeList1 && (
                      <div className="option-list">
                        {collegeList.length > 0 &&
                          collegeList.map((college, index) => {
                            return (
                              <div
                                className="option"
                                key={index}
                                onClick={() => selectCollege1(college)}
                              >
                                {college.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {selectedTab == 2 && departmentCount > 1 && (
                      <div
                        className="cross-btn"
                        onClick={() => closeDepartmentField()}
                      >
                        <ImCross />
                      </div>
                    )}
                  </div>
                </div>
                {selectedTab == 1 && (
                  <div className="input-box">
                    <div className="input-text">
                      <input
                        type="text"
                        className="input-field"
                        placeholder="Select department/school"
                        onChange={(e) => handleDepartment1Change(e)}
                        value={department1}
                        disabled={college1 == ""}
                        onClick={() => handleDepartment1Click()}
                      />
                      {showDepartmentList1 && (
                        <div className="option-list">
                          {departmentList.length > 0 &&
                            departmentList.map((department, index) => {
                              return (
                                <div
                                  className="option"
                                  key={index}
                                  onClick={() =>
                                    selectDepartment1(department.name)
                                  }
                                >
                                  {department.name}
                                </div>
                              );
                            })}
                        </div>
                      )}
                      {departmentCount > 1 && (
                        <div
                          className="cross-btn"
                          onClick={() => closeDepartmentField()}
                        >
                          <ImCross />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={
              "run-button " +
              `${departmentCount == 2 ? "btn-margin-bottom" : ""}`
            }
          >
            {/* <Link to="/result"> */}
            <Button
              text={departmentCount == 2 ? "COMPARE" : "EXPLORE"}
              icon={GradIcon}
              onClick={handleSubmit}
              disabled={setSubmitBtnDisable()}
            />
            {/* </Link> */}
          </div>
        </div>
        <ul className="content-container">
          <li className="content-text">
            Compare university departments by their after graduation carreer
            statistics
          </li>
          <li className="content-text">
            Explore the after graduation carreer distribution of university
            departments
          </li>
          <li className="content-text">
            Targeting a specific job in a specific company?
          </li>
          <li className="content-text">
            Compare & explore the ideal university department for a specific job
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};
